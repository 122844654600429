// 三组动效组合使用
@keyframes fadeIn {
  0.01%{
    opacity: 0.5;
  }
  0% {
    opacity: 1;
  }
  30%{
    opacity: 1;
  }
  35% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeIn2 {
  0% {
    opacity: 0;
  }
  30%{
    opacity: 0;
  }
  35% {
    opacity: 1;
  }
  65%{
    opacity: 1;
  }
  70% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeIn3 {
  0% {
    opacity: 0;
  }
  65% {
    opacity: 0;
  }
  70% {
    opacity: 1;
  }
  96% {
    opacity: 1;
  }
  100%{
    opacity: 0.1;
  }
}
// end 三组动效组合使用

@keyframes fadeInBottom {
  0% {
    opacity: 0;
    transform: translateY(200%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOutBottom {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(200%);
  }
}

